import type { ReactNode } from "react";

import styles from "./index.module.css";

export const SortableHeader = ({
	children,
	sortKey,
	onSort,
	activeSortKey,
	activeSortDirection,
}: {
	activeSortKey?: string;
	activeSortDirection?: "asc" | "desc";
	children: ReactNode;
	sortKey: string;
	onSort: (sortKey: string) => void;
}) => {
	const isActive = activeSortKey === sortKey;
	return (
		<button
			type="button"
			onClick={() => onSort(sortKey)}
			className={styles.button}
		>
			{children}
			<svg
				className={styles.icon}
				role="presentation"
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.66699 5.99984L8.00033 2.6665L11.3337 5.99984"
					stroke={
						isActive && activeSortDirection === "asc" ? "#262626" : "#D1D1D1"
					}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M4.6665 10L7.99984 13.3333L11.3332 10"
					stroke={
						isActive && activeSortDirection === "desc" ? "#262626" : "#D1D1D1"
					}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</button>
	);
};
