import { useField } from "formik";
import React, { useEffect } from "react";
import type { Properties } from "./properties";

export const FormikControlWrapperView = React.memo((props: Properties) => {
	const [field, meta, helpers] = useField({
		name: props.name,
		value: props.value,
		validate: props.validate,
	});

	useEffect(() => {
		if (meta.value !== props.value)
			helpers.setValue(props.value, props.validateOnChange || false);
	}, [props.value]);

	useEffect(() => {
		if (props.focused !== undefined && meta.touched !== props.focused)
			helpers.setTouched(props.focused, props.validateOnBlur || false);
	}, [props.focused]);

	useEffect(() => {
		if (meta.error !== props.error) helpers.setError(props.error);
	}, [props.error]);

	return props.component;
});
