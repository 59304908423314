import axios from "axios";
import type { AxiosError, AxiosResponse } from "axios";
import { isArray } from "lodash";

import { apiErrors } from "@app/constants/api-errors";
import { store } from "@app/redux";
import {
	setThrottlingTimeAction,
	setTokenHasExpiredAction,
} from "@app/redux/auth/auth-actions";

import { captureException, setContext } from "@sentry/react";
import type { GenericFailureResponse } from "./models/api";

let controller = new AbortController();

type ResponseInterceptorSuccess = (
	response: AxiosResponse<any, any>,
) => AxiosResponse | Promise<AxiosResponse>;

type ResponseInterceptorError = (
	response: AxiosError<any, any>,
) => void | Promise<void>;

type ComplexErrorType = { [key: string]: string[] };

type ManipulateStringArraysType =
	| null
	| undefined
	| string[]
	| ComplexErrorType;

const unauthorizedApi = axios.create({
	baseURL: "https://srv.futureforex.co.za/api/",
	signal: controller.signal,
});

const authorizedApi = axios.create({
	baseURL: "https://srv.futureforex.co.za/api/",
	signal: controller.signal,
});

export function setToken(token: string) {
	authorizedApi.defaults.headers.common.Authorization = `Token ${token}`;
}

export function abortRequests() {
	controller.abort();

	controller = new AbortController();

	unauthorizedApi.defaults.signal = controller.signal;
	authorizedApi.defaults.signal = controller.signal;
}

const formatSuccessResponseInterceptor: ResponseInterceptorSuccess = (
	response,
) => {
	try {
		// if returned data is a string try convert to object
		if (typeof response.data === "string")
			response.data = JSON.parse(response.data);
	} catch {
		// ignore parsing errors
	}
	return response;
};

const formatErrorResponseInterceptor: ResponseInterceptorError = async (
	error,
) => {
	try {
		if (error.response) {
			// report any error that is not successful, unauthorized or forbidden
			if (error.response.status > 403) {
				setContext("response", error.response);
				captureException(error);
			}

			if (error.request.responseType === "blob") {
				error.response.data = await error.response.data.text();
			}

			if (typeof error.response.data === "string")
				error.response.data = JSON.parse(error.response.data);

			if (
				[apiErrors.invalidToken, apiErrors.tokenExpired].indexOf(
					error.response.data.detail,
				) > -1
			) {
				abortRequests();
				store.dispatch(setTokenHasExpiredAction(true));
			} else if (
				[apiErrors.noCredentials].indexOf(error.response.data.detail) > -1
			) {
				abortRequests();
				store.dispatch({ type: "RESET" });
			}

			if (error.response.status === 429) {
				const retryAfterHeader = "retry-after";

				if (error.response?.headers?.[retryAfterHeader]) {
					const retrySeconds = +error.response.headers[retryAfterHeader];
					store.dispatch(
						setThrottlingTimeAction(
							Number.isFinite(retrySeconds) ? retrySeconds : 0,
						),
					);
				}
			}
		}
	} catch {
		if (error.response?.status === 500)
			error.response.data = {
				genericErrors: ["Internal server error."],
			} as GenericFailureResponse;
	}

	if (error.response)
		error.response.data = manipulateStringArrays(error.response.data);

	throw error.response;
};

export function manipulateStringArrays<T = ManipulateStringArraysType>(
	data: T,
): T {
	if (!data) return data;

	if (isArray(data)) {
		for (let i = 0; i < data.length; i++) {
			const entry = data[i];

			if (typeof entry === "string")
				data[i] = entry[0].toUpperCase() + entry.substring(1);
		}
	} else if (typeof data === "object") {
		const keys = Object.keys(data);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];

			(data as ComplexErrorType)[key] = manipulateStringArrays(
				(data as ComplexErrorType)[key],
			);
		}
	}

	return data;
}

authorizedApi.interceptors.response.use(
	formatSuccessResponseInterceptor,
	formatErrorResponseInterceptor,
);
unauthorizedApi.interceptors.response.use(
	formatSuccessResponseInterceptor,
	formatErrorResponseInterceptor,
);

export { authorizedApi, unauthorizedApi };
