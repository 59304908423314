import { SessionExpiredModal } from "@app/components/modals/session-expired-modal";
import { useClearUserData, useSetNewPageLoad } from "@app/helpers";
import type { RootState } from "@app/redux";
import { abortRequests } from "@app/services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const SessionWrapper = ({ children }: { children: React.ReactNode }) => {
	const [searchParams] = useSearchParams();
	const [clearUserData] = useClearUserData();
	const [setNewPageLoad] = useSetNewPageLoad();

	const isInactiveModalShown = searchParams.get("inactive") === "true";

	const [displaySessionExpiredModal, setDisplaySessionExpiredModal] =
		useState(false);

	const authState = useSelector((state: RootState) => state.auth);
	const { tokenHasExpired, newPageLoad } = authState ?? {};

	function onPageLoad(): void {
		setTimeout(() => {
			setNewPageLoad(false);
		}, 5000);
	}

	useEffect(() => {
		window.addEventListener("load", onPageLoad);

		return function cleanup() {
			abortRequests();
			window.removeEventListener("load", onPageLoad);
		};
	}, []);

	useEffect(() => {
		if (tokenHasExpired && !isInactiveModalShown) {
			clearUserData();
			if (!newPageLoad) setDisplaySessionExpiredModal(true);
		}
	}, [tokenHasExpired, isInactiveModalShown]);

	return (
		<>
			{children}
			{displaySessionExpiredModal && (
				<SessionExpiredModal
					open
					onClose={() => {
						setDisplaySessionExpiredModal(false);
					}}
				/>
			)}
		</>
	);
};
