import { useMediaQuery } from "@app/hooks/use-media-query";
import { useEffect, useState } from "react";
import type { MinimumReturnState } from "./models/minimum-return-state";
import type { Properties, ViewProperties } from "./properties";

import { Input } from "@app/controls/input";
import { formatNumber } from "@app/utils/format-number";
import { Popover } from "../popovers/popover/popover";
import "./minimum-return-card.css";
import clsx from "clsx";

import alertCircleSrc from "./alert-circle.svg";
import styles from "./index.module.css";

export const MinimumReturnCard = (props: Properties) => {
	const isMobile = useMediaQuery();
	const defaultState: MinimumReturnState = {
		percent: `${props.percent || 0}`,
	};

	const [state, setState] = useState<MinimumReturnState>(defaultState);

	const getMinimumReturn = () =>
		(props.investedAmount ?? 0) * (+state.percent * 0.01);

	const onChangePercent = (percent: string) => {
		setState({ ...state, percent: percent });

		if (props.onChange) props.onChange(percent);
	};

	useEffect(() => {
		if (props.percent && props.percent !== state.percent) {
			setState({ ...state, percent: props.percent });
		}
	}, [props.percent]);

	const viewProps: ViewProperties = {
		...props,
		minimumReturn: getMinimumReturn(),
		percent: state.percent,
		onChange: onChangePercent,
	};

	const getPercentageWidth = () => {
		// Avoid the decimal dot or comma from taking up a full width ch slot
		const percent = props.percent.toString();
		return (
			(percent.includes(".") || percent.includes(",")
				? percent.length - 0.5
				: percent.length) + 0.25
		);
	};

	return (
		<div className={clsx(styles.card, viewProps.className)}>
			<div className={styles.titleContainer}>
				<h2 className={styles.title}>Minimum Return</h2>

				<Popover
					toggleContent={
						<img
							className={styles.tooltip}
							src={alertCircleSrc}
							alt="More info"
						/>
					}
					popperContent={
						<p className={styles.tooltipContent}>
							This is the absolute minimum you will earn on your next trade.{" "}
							<strong>
								Note that you will typically earn considerably more.
							</strong>{" "}
							This figure is set to maximise your returns by ensuring your full
							allowance is traded during the year. It is calculated using past
							market data and machine learning.
						</p>
					}
					showArrow={true}
					showOnHover={!isMobile}
					flip={true}
					placement="bottom"
					offset={[16, 16]}
				/>
			</div>
			<div>
				<div className={styles.values}>
					<div className={styles.figure}>
						{viewProps.minimumReturn > 0
							? formatNumber(viewProps.minimumReturn, {
									currencyCode: "R ",
									decimalPlaces: viewProps.isDesktop ? 2 : 0,
									groupSeparator: ",",
								})
							: "R -"}
					</div>
					{viewProps.minimumReturn > 0 ? (
						<div className="flex flex-row font-primary-light leading-none minimum-return-percent">
							({" "}
							<div
								style={{
									// Use currently set font to maintain width of input control to match its text content, implicit with 'ch' unit
									width: `${getPercentageWidth()}ch`,
								}}
							>
								<Input
									className="-mt-0.5"
									hideIcon
									overrideShowValidation={true}
									readOnly
									theme="none"
									title=""
									type="number"
									value={`${viewProps.percent}`}
									onChange={viewProps.onChange}
								/>
							</div>
							%)
						</div>
					) : (
						<Popover
							toggleContent={
								<div className="minimum-return-popover">
									(Why is this blank?)
								</div>
							}
							popperContent={
								<div
									className="font-primary-regular leading-5 -m-2.5 p-2.5 z-100 minimum-return-footnote"
									dangerouslySetInnerHTML={{
										__html:
											"A Minimum Return will only show once a trade is loaded or in progress. If you'd like to get a trade loaded, speak to your Relationship Manager.",
									}}
								/>
							}
							showArrow={true}
							showOnHover={!isMobile}
							placement={!isMobile ? "right-start" : "bottom"}
							offset={!isMobile ? [0, 16] : [0, 16]}
						/>
					)}
				</div>
				<p className={styles.cycle}>Per trade cycle</p>
				<div className="grow" />
			</div>
		</div>
	);
};
