import { Modal } from "@app/components/modals/modal";
import { Popover } from "@app/components/popovers/popover";
import { Wizard } from "@app/components/wizard";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import { useSelector } from "react-redux";
import "./deposit-funds-modal.css";

const messages = {
	en: {
		depositFundsSubHeader: (bankName: string) => (
			<>
				You can deposit funds by sending an EFT
				<br />
				to your&nbsp;{bankName}&nbsp;account.
			</>
		),
		accountHolder: "Account Holder: ",
		bankName: "Bank: ",
		branchCode: "Branch Code: ",
		accountNumber: "Account Number: ",
		accountType: "Account Type: ",
		depositPopover:
			"The more you invest the better your returns will be. This is due to fixed costs and our performance share decreasing as a percentage of the total amount invested. Note that your investment amount can be increased or decreased at any stage of the investment.",
		feeTable: {
			headerContent: ["Investment Amount per Cycle", "Percentage Fee"],
			bodyContent: [
				["R100,000 – R149,999", "35%"],
				["R150,000 – R199,999", "33%"],
				["R200,000 – R299,999", "30%"],
				["R300,000 – R399,999", "28%"],
				["+R400,000", "25%"],
			],
		},
		sourceOfFundsContent: (
			rmName: string,
			rmEmail: string,
			clientName: string,
		) => `
        <p>Capitec Bank requires that source of funds are shown for funds deposited.</p>
        <br />
        <p>Please provide a bank statement showing the funds leaving the originating account and being sent to Capitec Bank. Proof of Payment (PoP) will not be accepted.</p>
        <br />
        <p>The statement can be sent to your Relationship Manager, ${rmName}, at
        <a href="mailto:${rmEmail}?subject=${encodeURIComponent(clientName)}%20-%20Source%20of%20funds">
        ${rmEmail}
        </a>
        </p>`,
	},
};

export const DepositFundsModalView = ({
	isSubmitting,
	...props
}: {
	isComplete: boolean;
	onNextStep: () => void;
	open: boolean;
	accountHolder: string;
	bankName: string;
	branchCode: string;
	accountNumber: string;
	accountType: string;
	rmName: string;
	rmEmail: string;
	clientName: string;
	onClose: () => void;
	isSubmitting: boolean;
}) => {
	const { language } = useSelector((state: RootState) => state.language);

	const getFeeTable = () => {
		return (
			<div className="fee-table flex flex-col items-center">
				<h4 className="mb-2">Table 1: Performance Fee Tiering</h4>
				<table>
					<thead>
						<tr>
							{messages[language].feeTable.headerContent.map((x, index) => (
								<th key={`header-${x}`} className="p-2">
									{x}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{messages[language].feeTable.bodyContent.map((row) => (
							<tr key={row.join("-")}>
								{row.map((cell, index) => (
									<td key={`cell-${index}`}>{cell}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<Modal
			className="deposit-funds-modal"
			open={props.open}
			onClose={props.onClose}
		>
			<Wizard
				isComplete={props.isComplete}
				forms={[
					<>
						<h1 className="font-bold text-2xl mb-8">Deposit Funds</h1>
						<div className="account-details text-lg mb-9">
							<span>
								{messages[language].depositFundsSubHeader(props.bankName)}
							</span>
							<br />
							<br />
							<h3 className="font-bold">Account Details</h3>
							<p>
								<span>{messages[language].accountHolder}</span>
								{props.accountHolder}
							</p>
							<p>
								<span>{messages[language].bankName}</span>
								{props.bankName}
							</p>
							<p>
								<span>{messages[language].branchCode}</span>
								{props.branchCode}
							</p>
							<p>
								<span>{messages[language].accountNumber}</span>
								{props.accountNumber}
							</p>
							<p className="mb-9">
								<span>{messages[language].accountType}</span>
								{props.accountType}
							</p>
							<Popover
								className="w-full"
								toggleContent={
									<a href="#" target="_self">
										How much should I deposit?
									</a>
								}
								popperContent={
									<div className="font-primary-medium -ml-2.5 p-2.5 z-10 deposit-info overflow-y-auto">
										{messages[language].depositPopover}
										<br />
										<br />
										{getFeeTable()}
									</div>
								}
								showArrow={true}
								placement="top-start"
								offset={[8, 16]}
							/>
						</div>
					</>,
				]}
				completeForm={
					<>
						<h1 className="font-bold text-2xl mb-8">Source of Funds</h1>
						<div
							className="source-of-funds text-lg mb-9"
							dangerouslySetInnerHTML={{
								__html: messages[language].sourceOfFundsContent(
									props.rmName,
									props.rmEmail,
									props.clientName,
								),
							}}
						/>
					</>
				}
				hideProgress
			/>
			<Button
				disabled={isSubmitting}
				className="done"
				theme="primary"
				onClick={props.onNextStep}
			>
				{props.isComplete ? "DONE" : "I HAVE TRANSFERRED FUNDS"}
			</Button>
		</Modal>
	);
};
