import type { RootState } from "@app/redux";
import { type ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalView } from "./modal-view";

export const Modal = (props: {
	children?: ReactNode;
	className?: string;
	footerContent?: ReactNode;
	ignoreTokenExpiry?: boolean;
	noCloseButton?: boolean;
	open: boolean;
	overlayColor?: "light" | "dark";
	titleContent?: ReactNode;
	disableOverlayOnClose?: boolean;
	onClose?: () => void;
	onCloseTransitionEnd?: () => void;
}) => {
	const { tokenHasExpired, userIsAuthenticated } = useSelector(
		(rootState: RootState) => rootState.auth,
	);

	const [openState, setOpenState] = useState(false);

	useEffect(() => {
		if (openState !== props.open) setOpenState(props.open);
	}, [props.open]);

	const onClose = () => {
		setOpenState(false);
		setTimeout(() => {
			props.onClose?.();
		}, 300);
	};

	const canOpen = () => {
		return !props.ignoreTokenExpiry
			? tokenHasExpired !== undefined
				? openState && !!userIsAuthenticated && tokenHasExpired
				: openState
			: openState;
	};

	return <ModalView {...props} open={canOpen()} onClose={onClose} />;
};
