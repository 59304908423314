import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { Language } from "src/types";

export type State = {
	language: Language;
};

export const defaultState: State = {
	language: "en",
};

const slice = createSlice({
	name: "language",
	initialState: defaultState,
	reducers: {
		setLanguage(state, action: PayloadAction<Language>) {
			state.language = action.payload;
		},
	},
});

export const { setLanguage } = slice.actions;

export default slice.reducer;
