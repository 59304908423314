import type { Notification } from "@app/entities";
import { useGetNotifications, useSetNotificationRead } from "@app/helpers";
import type { RootState } from "@app/redux";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NotificationsView } from "./notifications-view";

export const Notifications = (props: {
	className?: string;
}) => {
	const { profile } = useSelector((state: RootState) => state.auth);

	const { notifications } = useSelector(
		(state: RootState) => state.notifications,
	);

	const { notificationsRead } = useSelector(
		(state: RootState) => state.notificationsRead,
	);

	const [getNotifications] = useGetNotifications();
	const [setNotificationRead] = useSetNotificationRead();

	const markAsRead = (index: number) =>
		setNotificationRead(state.notificationList[index]);

	const sortNotifications = (a: Notification, b: Notification) => {
		if (a.read && !b.read) return 1;
		if (!a.read && b.read) return -1;
		if (a.priority > b.priority) return -1;
		if (a.priority < b.priority) return 1;
		return 0;
	};

	const mapNotificationsToState = () => {
		let cachedNotifications: number[] = [];

		if (profile?.id && notificationsRead) {
			cachedNotifications =
				notificationsRead.find((x) => x.userId === profile.id)
					?.notificationIds || [];
		}

		return (
			notifications
				?.map((x) => ({
					...x,
					read: cachedNotifications.includes(x.id),
				}))
				.sort(sortNotifications) || []
		);
	};

	const [state, setState] = useState({
		notificationList: mapNotificationsToState(),
		open: false,
	});

	const onRead = (index: number) => {
		markAsRead(index);

		const tempNotificationList = cloneDeep(state.notificationList);
		tempNotificationList[index].read = true;

		setState({
			...state,
			notificationList: tempNotificationList,
		});
	};

	const onToggle = (toggle: boolean) => {
		if (toggle) {
			const tempNotificationList = cloneDeep(state.notificationList);
			tempNotificationList.sort(sortNotifications);
			setState({
				...state,
				open: true,
				notificationList: tempNotificationList,
			});
		} else {
			setState({
				...state,
				open: false,
				notificationList: state.notificationList,
			});
		}
	};

	useEffect(() => {
		getNotifications();
	}, []);

	useEffect(() => {
		setState({ ...state, notificationList: mapNotificationsToState() });
	}, [notifications]);

	return (
		<NotificationsView
			{...props}
			notificationList={state.notificationList}
			open={state.open}
			onRead={onRead}
			onToggle={onToggle}
		/>
	);
};
