import { isLocal, isTest } from "@app/config/env";

export const links = {
	contact: "https://futureforex.co.za/contact/",
	forexLogin: (isInactive = false) => {
		if (isTest || isLocal) {
			return `https://test-account.futureforex.co.za${isInactive ? "/arbitrage/not-verified" : "/login"}`;
		}
		return `https://account.futureforex.co.za${isInactive ? "/arbitrage/not-verified" : "/login"}`;
	},
	getInTouch: {
		emailAddress: "mailto:info@futureforex.co.za",
		phoneNumber: "tel:+27 (0)21 518 0558",
		physicalAddress:
			"https://www.google.com/maps/place/66+Roeland+St,+Gardens,+Cape+Town,+8001,+South+Africa/" +
			"@-33.93014,18.4202713,17z/data=!3m1!4b1!4m5!3m4!1s0x1dcc677c6dee2f25:0xfc54dc852efce006!8m2!3d-33.93014!4d18.42246",
	},
	home: "https://futureforex.co.za/",
	legal: {
		privacyPolicy: "https://futureforex.co.za/privacy-policy/",
		termsAndConditions: "https://futureforex.co.za/terms-and-conditions/",
		termsOfUse: "https://futureforex.co.za/website-terms-of-use/",
	},
	sourceOfFundsFaq:
		"https://www.resbank.co.za/en/home/quick-links/frequently-asked-questions#finsurv-item-1869cf2e7d-button",
	quickLinks: {
		about: "https://futureforex.co.za/about/",
		careers: "https://futureforex.co.za/careers/",
		faqs: "https://futureforex.co.za/faq/",
	},
};
