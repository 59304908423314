import type { ValidationErrors } from "@app/constants/validation-errors";
import type { Language } from "src/types";

interface Messages {
	validationError: { [key in ValidationErrors]: string };
}

export type Message = {
	[key in Language]: Messages;
};

export const commonMessages: Message = {
	en: {
		validationError: {
			currency: "Please enter a valid monetary value",
			custom: "Please enter a valid value",
			date: "Please enter a valid date",
			email: "Please enter a valid email address",
			maxLength: "The value entered is too long",
			minLength: "The value entered is too short",
			numeric: "Please enter a valid number",
			required: "This field is required",
			telephone: "Please enter a valid phone number",
		},
	},
};
