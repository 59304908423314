import React, { useEffect, useState } from "react";
import type { FormErrors, FormValues, Properties } from "./properties";
import { WithdrawAmountModalView } from "./withdraw-amount-modal-view";

interface State {
	errors?: string[];
	validate: boolean;
	withdrawAll: boolean;
	straightThroughProcess: boolean;
	bankId: number;
}

export const WithdrawAmountModal = (props: Properties) => {
	const [state, setState] = useState<State>({
		errors: [],
		validate: false,
		withdrawAll: false,
		straightThroughProcess: false,
		bankId: 0,
	});

	const onClearErrors = () => {
		setState({ ...state, errors: [] });
	};

	const onFormSubmit = (values: FormValues) => {
		const amount = values.amount ? +values.amount : 0;
		const withdrawAll = values.withdrawAll as boolean;
		props.onSubmit(
			amount,
			withdrawAll,
			props.bankId,
			state.straightThroughProcess,
		);
	};

	const onValidate = (errors: FormErrors, submit: () => void) => {
		if (Object.keys(errors).length > 0) {
			setState({ ...state, validate: true });
			return;
		}

		submit();
	};

	const onWithdrawAllDropdownSelect = (value: boolean) => {
		if (!value) {
			setState((state) => {
				return {
					...state,
					withdrawAll: false,
				};
			});
		} else {
			setState((state) => {
				return {
					...state,
					withdrawAll: true,
				};
			});
		}
	};

	const onWithdrawTypeDropdownSelect = (value: string) => {
		if (value === "STP") {
			setState((state) => {
				return {
					...state,
					straightThroughProcess: true,
				};
			});
		} else {
			setState((state) => {
				return {
					...state,
					straightThroughProcess: false,
				};
			});
		}
	};

	useEffect(() => {
		if (props.errors !== state.errors) {
			setState({ ...state, errors: props.errors });
		}
	}, [props.errors]);

	useEffect(() => {
		if (!props.open) setState({ ...state, validate: false });
	}, [props.open]);

	return (
		<WithdrawAmountModalView
			{...props}
			withdrawAll={state.withdrawAll}
			errors={state.errors}
			mappedErrors={props.mappedReasons}
			validate={state.validate}
			onClearErrors={onClearErrors}
			onFormSubmit={onFormSubmit}
			onValidate={onValidate}
			onWithdrawAllDropdownSelect={onWithdrawAllDropdownSelect}
			onWithdrawTypeDropdownSelect={onWithdrawTypeDropdownSelect}
			onSubmit={props.onSubmit}
		/>
	);
};
