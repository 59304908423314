import { useSetThrottlingTime } from "@app/helpers";
import { useEffect } from "react";

export const ThrottleManager = () => {
	const [setThrottlingTime] = useSetThrottlingTime();

	useEffect(() => {
		setThrottlingTime(0);
	}, []);

	return <></>;
};
