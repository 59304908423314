import clsx from "clsx";
import styles from "./index.module.css";

export const LegalDisclaimer = ({
	className,
}: {
	className?: string;
}) => {
	return (
		<p className={clsx(styles.container, className)}>
			Future Forex SA (Pty) Ltd is an authorised FSP
		</p>
	);
};
