import type { RootState } from "@app/redux";
import { authorizedApi } from "@app/services";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DepositFundsModalView } from "./deposit-funds-modal-view";

export const DepositFundsModal = (props: {
	open: boolean;
	accountHolder: string;
	bankName: string;
	branchCode: string;
	accountNumber: string;
	accountType: string;
	rmName: string;
	rmEmail: string;
	clientName: string;
	onClose: () => void;
}) => {
	const { activeClient } = useSelector(
		(rootState: RootState) => rootState.clients,
	);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isComplete, setIsComplete] = useState(false);

	const onNextStep = async () => {
		if (!isComplete) {
			if (activeClient?.id) {
				try {
					setIsSubmitting(true);
					await authorizedApi.post(
						`client_deposited_funds/${activeClient.id}/`,
					);
				} catch (error) {
					captureException(error);
				} finally {
					setIsSubmitting(false);
				}
			}
			setIsComplete(true);
			return;
		}

		props.onClose();
	};

	return (
		<DepositFundsModalView
			{...props}
			onNextStep={onNextStep}
			isSubmitting={isSubmitting}
			isComplete={isComplete}
		/>
	);
};
