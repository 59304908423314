import { Button } from "@app/controls/button";
import { Dialog, Transition } from "@headlessui/react";
import React, { type ReactNode } from "react";
import "./modal.css";
import { IoCloseSharp } from "react-icons/io5";

export const ModalView = React.memo(
	(props: {
		children?: ReactNode;
		className?: string;
		footerContent?: ReactNode;
		ignoreTokenExpiry?: boolean;
		noCloseButton?: boolean;
		open: boolean;
		overlayColor?: "light" | "dark";
		titleContent?: ReactNode;
		disableOverlayOnClose?: boolean;
		onClose?: () => void;
		onCloseTransitionEnd?: () => void;
	}) => {
		const getDialogClasses = () => {
			const classesToUse = [
				"modal flex justify-center items-center h-screen fixed inset-0 z-50",
			];

			if (props.className) classesToUse.push(props.className);

			return classesToUse.join(" ");
		};

		const getOverlayClasses = () => {
			const classesToUse = ["modal-overlay w-full h-full absolute inset-0"];

			if (props.overlayColor === "light") classesToUse.push("bg-white/50");
			else classesToUse.push("bg-black/50");

			return classesToUse.join(" ");
		};

		const getContentClasses = () => {
			const classesToUse = [
				"px-6 pb-12 lg:px-12 flex-row flex-grow flex-shrink overflow-y-auto content",
			];

			if (props.noCloseButton && props.titleContent === undefined)
				classesToUse.push("pt-12");

			return classesToUse.join(" ");
		};

		const getHeader = () => {
			if (!props.titleContent && props.noCloseButton) return <></>;

			return (
				<div
					className={[
						"flex mx-3 my-3 closure",
						props.titleContent ? "justify-between" : "justify-end",
					].join(" ")}
				>
					{props.titleContent !== undefined ? props.titleContent : undefined}
					{!props.noCloseButton && (
						<Button
							className="close-button grow-0"
							onClick={() => props.onClose?.()}
						>
							<IoCloseSharp className={"icon-filled"} size="35px" />
						</Button>
					)}
				</div>
			);
		};

		return (
			<Transition
				show={props.open}
				as={React.Fragment}
				afterLeave={() =>
					props.onCloseTransitionEnd &&
					setTimeout(props.onCloseTransitionEnd, 300)
				}
			>
				<Dialog
					className={getDialogClasses()}
					onClose={() => {
						if (!props.disableOverlayOnClose) props.onClose?.();
					}}
				>
					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className={getOverlayClasses()} />
					</Transition.Child>
					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div
							className={"modal-content flex flex-col max-h-full bg-white z-10"}
						>
							{getHeader()}

							<div className={getContentClasses()}>{props.children}</div>
							{props.footerContent}
						</div>
					</Transition.Child>
				</Dialog>
			</Transition>
		);
	},
);
