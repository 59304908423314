import { CustomLoader } from "@app/components/custom-loader";
import { OnboardingInProgressModal } from "@app/components/modals/onboarding-in-progress-modal";
import { paths } from "@app/constants/paths";
import type { ClientSummariesResponse } from "@app/entities";
import { useGetClient, useGetClientSummaries } from "@app/helpers";
import type { RootState } from "@app/redux";
import routes from "@app/routes";
import type React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const ClientWrapper = ({ children }: { children: React.ReactNode }) => {
	const [getClient] = useGetClient();
	const [getClientSummaries] = useGetClientSummaries();

	const location = useLocation();

	const navigate = useNavigate();

	const { activeClient, activeClientLoading } = useSelector(
		(state: RootState) => state.clients,
	);

	const isClientRoute = () => {
		return (
			routes.find((x) => x.path === location.pathname && x.client) !== undefined
		);
	};

	useEffect(() => {
		if (isClientRoute()) {
			const activeClientIsSet = !!activeClient?.id;

			getClientSummaries(
				0,
				undefined,
				(summaryResponse?: string[] | ClientSummariesResponse | undefined) => {
					const clientList = summaryResponse as ClientSummariesResponse;

					if (
						!activeClientIsSet &&
						clientList &&
						clientList.clients &&
						clientList.clients.length > 1
					) {
						navigate(paths.clients);
					}
				},
			);

			if (activeClientIsSet) {
				getClient(activeClient.id);
			}
		}
	}, [location]);

	return (
		<>
			{isClientRoute() && activeClient?.onboardingComplete === false && (
				<OnboardingInProgressModal open />
			)}
			{children}
			{activeClientLoading && <CustomLoader page />}
		</>
	);
};
