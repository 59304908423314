import { Helmet } from "react-helmet";
import { isLocal, isPWA, isTest } from "./config/env";

export const MetaTags = () => {
	const showTestIcon = isLocal || isTest;
	const faviconName = showTestIcon ? "favicon-test" : "favicon";
	return (
		<Helmet>
			<link
				rel="icon"
				type="image/png"
				href={`${faviconName}-48x48.png`}
				sizes="48x48"
			/>
			<link rel="icon" type="image/svg+xml" href={`${faviconName}.svg`} />
			<link rel="shortcut icon" href={`${faviconName}.ico`} />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={`${faviconName}-180x180.png`}
			/>

			{isPWA && (
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
			)}
		</Helmet>
	);
};
