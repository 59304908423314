import downloadHistory from "@app/assets/images/download-history.svg";
import { CustomLoader } from "@app/components/custom-loader";
import { StatusModal } from "@app/components/modals/status-modal";
import { Navbar } from "@app/components/navbar";
import { Sidebar } from "@app/components/sidebar";
import { type Row, Table } from "@app/components/table";
import { dateFormats } from "@app/constants/date-formats";
import { Button } from "@app/controls/button";
import { Dropdown } from "@app/controls/dropdown";
import { Input } from "@app/controls/input";
import type { Cycle } from "@app/entities";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import moment from "moment";
import React from "react";
import "./trades.css";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { FiDownload } from "react-icons/fi";
import styles from "./trades-view.module.css";

const downloadFormatItems = [
	{
		label: "CSV",
		value: "CSV",
	},
	{
		label: "PDF",
		value: "PDF",
	},
];

const TradesView = React.memo(
	(props: {
		cycles: Cycle[];
		cyclesTotal: number;
		endDate: string;
		error?: string;
		isDesktop?: boolean;
		loadingData?: boolean;
		loadingPage?: boolean;
		page: number;
		pageCount: number;
		pageSize: number;
		onPageSizeChange: (pageSize: number) => void;
		showErrors: boolean;
		startDate: string;
		onChangeEndDate: (date: string) => void;
		onChangeStartDate: (date: string) => void;
		onClearErrors: () => void;
		onDownloadHistory: (format: string) => void;
		onDownloadStatement: (identifier: number) => void;
		onNavigatePage: (page: number) => void;
	}) => {
		const isMobile = useMediaQuery();

		const contentInnerStyle = [
			styles.card,
			"pt-7",
			"trades-screen-content-inner",
		].join(" ");

		const dropdownInnerStyle = [
			"h-10",
			"z-10",
			props.pageCount === 0 ? "trades-screen-disabled" : "",
		].join(" ");

		const formStyle = [
			"flex flex-row justify-between gap-x-2 lg:gap-x-5 items-end pt-5",
			props.cycles.length > 0 && "mb-8",
		].join(" ");

		const mobileCycleCountStyle = [
			"trades-screen-cycle mobile",
			props.cyclesTotal > 0 ? "-my-6" : "my-2",
		].join(" ");

		const getCycleCountContent = () => `(${props.cyclesTotal} cycles)`;

		const getFormattedCycleSummary = (cycle: Cycle): Cycle => {
			const currencyOptions: FormatNumberOptions = {
				currencyCode: "R ",
				decimalPlaces: 2,
				maxSignificantDigits: isMobile ? 3 : undefined,
				removeTrailingZeros: isMobile,
				groupSeparator: ",",
				formatUnit: isMobile,
			};

			const percentageOptions: FormatNumberOptions = {
				decimalPlaces: 2,
				groupSeparator: ",",
				isPercentage: true,
			};

			const defaultValue = "R 0";

			const clientProfit =
				+cycle.clientProfit > 0
					? formatNumber(cycle.clientProfit ?? 0, currencyOptions)
					: defaultValue;

			const profitPercent = +cycle.clientProfitPercentage;

			const clientProfitPercentage =
				profitPercent > 0
					? formatNumber(profitPercent * 100, percentageOptions)
					: "0%";

			const zarIn =
				+cycle.zarIn > 0
					? formatNumber(cycle.zarIn ?? 0, currencyOptions)
					: defaultValue;

			return {
				clientProfit,
				clientProfitPercentage,
				cycleCode: cycle.cycleCode,
				id: cycle.id,
				startDate: cycle.startDate,
				zarIn,
			};
		};

		const getCyclesDownloader = (identifier: number) => (
			<Button
				className="icon-button shrink-0 grow-0 p-0 mr-1 lg:mr-6 opacity-50"
				onClick={() => props.onDownloadStatement(identifier)}
			>
				<FiDownload size="20px" />
			</Button>
		);

		const getTableRow = (item: Cycle): Row => {
			const cycle = getFormattedCycleSummary(item);

			if (isMobile) {
				return {
					cells: [
						{
							content: moment(cycle.startDate, dateFormats.iso8601).format(
								dateFormats.reverseIso8601ShortSlash,
							),
						},
						{
							className: "whitespace-nowrap",
							content: cycle.zarIn,
						},
						{
							className: "whitespace-nowrap",
							content: `${cycle.clientProfit} (${cycle.clientProfitPercentage})`,
						},
						{
							content: getCyclesDownloader(cycle.id),
						},
					],
				};
			}

			return {
				cells: [
					{
						content: moment(cycle.startDate, dateFormats.iso8601).format(
							dateFormats.reverseIso8601Slash,
						),
					},
					{
						content: cycle.cycleCode,
					},
					{
						className: "whitespace-nowrap",
						content: cycle.zarIn,
					},
					{
						className: "whitespace-nowrap",
						content: cycle.clientProfit,
					},
					{
						content: cycle.clientProfitPercentage,
					},
					{
						content: getCyclesDownloader(cycle.id),
					},
				],
			} as Row;
		};

		const getTableHeadersContent = (): Row => {
			if (isMobile) {
				return {
					cells: [
						{
							content: "Date",
						},
						{
							content: "Amount",
						},
						{
							content: "Net Profit",
						},
						{
							content: "",
						},
					],
				};
			}

			return {
				cells: [
					{
						content: "Date",
					},
					{
						content: "Cycle Code",
					},
					{
						content: "Amount Invested",
					},
					{
						content: "Net Profit",
					},
					{
						content: "Net Return",
					},
					{
						content: "Statement",
					},
				],
			};
		};

		return (
			<>
				{(props.loadingPage || props.loadingData) && <CustomLoader page />}
				<div className="min-h-screen w-full trades-screen">
					<Navbar />
					<div className="flex">
						<Sidebar />
						<div className="content flex flex-col min-w-0 gap-x-10.5 mx-6 lg:mx-14 mt-7 grow">
							<div className="font-secondary-regular trades-screen-header">
								Trades
							</div>
							<div className={contentInnerStyle}>
								<div className="px-4 lg:px-7">
									<div className="font-primary-bold trades-screen-subheader">
										History
									</div>
									<div className="flex flex-col font-primary-medium">
										<div className={formStyle}>
											<div className="flex flex-row w-1/2 min-w-0 gap-x-2 grow lg:gap-x-5 shrink trades-screen-input-group">
												<div className="flex flex-col gap-y-2 min-w-0 grow">
													<div className="overflow-hidden whitespace-nowrap trades-screen-label">
														Start Date
													</div>
													<Input
														className="h-10 trades-screen-input"
														theme="secondary"
														title="Enter the start date for trades"
														type="date"
														value={props.startDate}
														onChange={props.onChangeStartDate}
													/>
												</div>
												<div className="flex flex-col gap-y-2 min-w-0 grow">
													<div className="overflow-hidden whitespace-nowrap trades-screen-label">
														End Date
													</div>
													<Input
														className="h-10 trades-screen-input"
														theme="secondary"
														title="Enter the end date for trades"
														type="date"
														value={props.endDate}
														onChange={props.onChangeEndDate}
													/>
												</div>
												<div className="hidden self-end mb-2.5 trades-screen-cycle desktop">
													{getCycleCountContent()}
												</div>
											</div>

											<div className="flex flex-row items-center min-w-0 gap-x-l mr-0 shrink-0">
												<Dropdown
													disabled={props.pageCount === 0}
													chevron={
														props.isDesktop ? undefined : (
															<img src={downloadHistory} alt="" />
														)
													}
													className={dropdownInnerStyle}
													theme={props.isDesktop ? "primary" : "navbar"}
													header={props.isDesktop ? "Download History" : ""}
													fixedHeader
													items={downloadFormatItems}
													onSelect={props.onDownloadHistory}
												/>
											</div>
										</div>
										<div className={mobileCycleCountStyle}>
											{getCycleCountContent()}
										</div>
									</div>
								</div>
								{!props.loadingData && props.cycles.length === 0 ? (
									<div className="font-primary-regular my-14 opacity-70 text-base text-center">
										No trade data to display
									</div>
								) : (
									<Table
										className="trades-table"
										blockSize={props.pageSize}
										count={props.cyclesTotal}
										onBlockSizeChange={props.onPageSizeChange}
										currentPage={props.page}
										data={props.cycles.map((x) => getTableRow(x))}
										headers={getTableHeadersContent()}
										pagination={props.cycles.length > 0 && props.pageCount > 0}
										totalPages={props.pageCount}
										onNavigatePage={props.onNavigatePage}
									/>
								)}
							</div>
							<LegalDisclaimer />
						</div>
					</div>
				</div>
				{props.showErrors && (
					<StatusModal
						open
						message={props.error}
						showCloseButton
						status="ERROR"
						onClose={props.onClearErrors}
					/>
				)}
			</>
		);
	},
);

export { TradesView };
