import type { RootState } from "@app/redux";
import { pathNames, paths } from "src/constants/paths";
import type { Language } from "src/types";
import type { Route } from ".";

import AccountSettingsView from "@app/screens/account-settings";
import Clients from "@app/screens/clients";
import Dashboard from "@app/screens/dashboard";
import ForgotPassword from "@app/screens/forgot-password";
import Login from "@app/screens/login";
import NotFound from "@app/screens/not-found";
import Register from "@app/screens/register";
import Trades from "@app/screens/trades";

/**
 * If a route is a sub-route of another, the parent must pass Fwn the child route via its
 * children property.
 */
const routes: Route[] = [
	{
		component: NotFound,
		path: paths.notFound,
		public: true,
	},
	{
		component: Clients,
		displayName: (language: Language) => pathNames[language].clients,
		index: 4,
		path: paths.clients,
		public: false,
		showInSidebarMobile: (rootState: RootState) =>
			(rootState.clients?.clientsTotalCount || 0) > 1,
	},
	{
		client: true,
		component: Dashboard,
		displayName: (language: Language) => pathNames[language].dashboard,
		index: 1,
		path: paths.dashboard,
		public: false,
		showInSidebar: true,
		showInSidebarMobile: true,
	},
	{
		component: AccountSettingsView,
		displayName: (language: Language) => pathNames[language].account,
		index: 4,
		path: paths.account,
		public: false,
		showInSidebar: false,
		showInSidebarMobile: true,
	},
	{
		component: Login,
		index: 5,
		path: paths.login(),
		public: true,
	},
	{
		client: true,
		component: Trades,
		displayName: (language: Language) => pathNames[language].trades,
		index: 2,
		path: paths.trades,
		showInSidebar: true,
		showInSidebarMobile: true,
	},
	{
		component: NotFound,
		path: paths.clients,
	},
	{
		component: ForgotPassword,
		path: paths.forgotPassword,
		public: true,
	},
	{
		component: Register,
		path: paths.register,
		public: true,
	},
	{
		component: Login,
		path: paths.activation,
		public: true,
	},
	{
		component: Login,
		path: paths.reset,
		public: true,
	},
];

export default routes;
