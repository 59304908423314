import { useMediaQuery } from "@app/hooks/use-media-query";

import { Popover } from "@app/components/popovers/popover";
import { ComboButton } from "@app/controls/combo-button";
import { formatNumber } from "@app/utils/format-number";
import "./balance-card.css";
import clsx from "clsx";
import { FaInfoCircle } from "react-icons/fa";

import styles from "./index.module.css";

const balanceAction = {
	addFunds: "ADD_FUNDS",
	withdraw: "WITHDRAW",
};

export const BalanceCard = (props: {
	className?: string;
	balance: number;
	balanceLastUpdated: string;
	balanceLastUpdatedWarning: string;
	onDeposit?: () => void;
	onWithdrawOption: (value: string) => void;
}) => {
	const isMobile = useMediaQuery();
	const handleBalanceAction = (action: string) => {
		switch (action) {
			case balanceAction.addFunds: {
				props.onDeposit?.();
				break;
			}
			case balanceAction.withdraw: {
				props.onWithdrawOption(action);
				break;
			}
			default: {
				break;
			}
		}
	};

	const viewProps = {
		...props,
		onBalanceAction: handleBalanceAction,
	};

	const desktopNumberOptions = {
		currencyCode: "R ",
		decimalPlaces: 2,
		groupSeparator: ",",
	};

	const mobileNumberOptions = {
		currencyCode: "R ",
		decimalPlaces: viewProps.balance >= 1000 ? 2 : 0,
		formatUnit: true,
	};

	const actionOptions = [
		{
			value: balanceAction.addFunds,
			label: "Add Funds",
		},
		{
			value: balanceAction.withdraw,
			label: "Withdraw",
		},
	];

	return (
		<div className={clsx(styles.card, viewProps.className)}>
			<div className="flex flex-col balance-card-inner mr-auto w-full">
				<div className={styles.titleContainer}>
					<h2 className={styles.title}>Balance</h2>
					<ComboButton
						className="balance-card-combo-button pb-0 z-0"
						buttonClassName="pb-1.5"
						fixedValue={
							actionOptions.length > 0 ? actionOptions[0].value : undefined
						}
						fixedValueHiddenInDropdown
						items={actionOptions}
						onClick={(value: string) => viewProps.onBalanceAction(value)}
					/>
				</div>
				<div className={styles.figure}>
					{viewProps.balance > 0
						? formatNumber(
								viewProps.balance,
								!isMobile ? desktopNumberOptions : mobileNumberOptions,
							)
						: "R 0"}
				</div>
				{viewProps.balanceLastUpdated && (
					<div className="balance-card-last-updated flex">
						<div>{viewProps.balanceLastUpdated}</div>
						{viewProps.balanceLastUpdatedWarning && (
							<div>
								<Popover
									toggleContent={
										<FaInfoCircle
											className="opacity-50 balance-card icon-filled ml-2"
											size="18px"
										/>
									}
									popperContent={
										<div
											className="font-primary-regular leading-5 -mt-2.5 mx-2.5 p-2.5 footnote"
											dangerouslySetInnerHTML={{
												__html: viewProps.balanceLastUpdatedWarning,
											}}
										/>
									}
									showArrow={true}
									showOnHover={!isMobile}
									flip={true}
									placement="bottom"
									offset={[16, 16]}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
