import type { DropdownItem } from "@app/entities";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./combo-button.css";
import { FaChevronDown } from "react-icons/fa";
import type { ViewProperties } from "./properties";

const ComboButtonView = (props: ViewProperties) => {
	const [menuWidth, setMenuWidth] = useState(0);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				entries.forEach((entry) => setMenuWidth(entry.target.clientWidth));
			}),
		[],
	);

	const buttonStyle = [
		"w-full",
		"focus:z-10",
		"font-primary-bold",
		"inline-flex",
		"items-center",
		"px-4",
		"py-2",
		"relative",
		"whitespace-nowrap",
		"combo-button-button",
		props.buttonClassName,
	].join(" ");

	const containerStyle = [
		"inline-flex",
		"relative",
		"z-10",
		"combo-button",
		props.className,
	].join(" ");

	const menuButtonStyle = [
		"flex",
		"h-full",
		"items-center",
		"px-2",
		"py-3",
		"combo-button-menu-button",
		props.menuButtonClassName,
	].join(" ");

	const menuStyle = [
		"absolute",
		"active:outline-none",
		"focus:outline-none",
		"origin-right",
		"right-0",
		"combo-button-menu",
		props.menuClassName,
	].join(" ");

	const menuItemStyle = [
		"font-primary-regular",
		"pl-3.5",
		"pr-5",
		"py-2.5",
		"break-all",
		"combo-button-item",
		props.itemClassName,
	].join(" ");

	const getButtonContent = () => (
		<button
			type="button"
			className={buttonStyle}
			onClick={() =>
				props.onClick &&
				(props.fixedItem
					? props.onClick(props.fixedItem.value)
					: props.onClick())
			}
		>
			{props.fixedItem ? props.fixedItem.label : props.selectedItem?.label}
		</button>
	);

	const getMenuItemsContent = () => (
		<Menu.Items className={menuStyle} style={{ width: `${menuWidth}px` }}>
			{props.items.map((item: DropdownItem<string>) => (
				<Menu.Item key={item.value}>
					{() => {
						return (
							<div className="flex flex-row justify-start">
								<button
									type="button"
									className={["text-left w-full"].join(" ")}
									disabled={item.disabled}
									onClick={() =>
										props.fixedItem
											? props.onClick?.(item.value)
											: props.onSelect?.(item.value)
									}
								>
									<div
										className={[
											menuItemStyle,
											!props.fixedItem &&
												props.selectedItem?.value === item.value &&
												"selected",
											item.disabled && "disabled",
										].join(" ")}
									>
										{item.label}
									</div>
								</button>
							</div>
						);
					}}
				</Menu.Item>
			))}
		</Menu.Items>
	);

	const getMenuButtonContent = () => (
		<Menu as="div" className="relative block">
			<Menu.Button className={menuButtonStyle}>
				{props.chevron ?? <FaChevronDown className="icon" size="13px" />}
			</Menu.Button>
			<Transition
				as={Fragment}
				enter="transition duration-300 ease-out"
				enterFrom="transform -translate-y-1 opacity-0"
				enterTo="transform translate-y-0 opacity-100"
			>
				{getMenuItemsContent()}
			</Transition>
		</Menu>
	);

	useEffect(() => {
		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return (
		<div
			className={containerStyle}
			ref={(element) => {
				if (element) {
					resizeObserver.disconnect();
					resizeObserver.observe(element);
				}
			}}
		>
			{getButtonContent()}
			{getMenuButtonContent()}
		</div>
	);
};

export { ComboButtonView };
