import { LogoutModal } from "@app/components/modals/logout-modal";
import { Modal } from "@app/components/modals/modal";
import { NewClientModal } from "@app/components/modals/new-client-modal";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import "./onboarding-in-progress-modal.css";
import type { ViewProperties } from "./properties";

export const OnboardingInProgressModalView = React.memo(
	(props: ViewProperties) => {
		const { language } = useSelector(
			(rootState: RootState) => rootState.language,
		);

		return (
			<>
				{props.showLogoutModal && (
					<LogoutModal open onClose={props.onCloseLogoutModal} />
				)}
				{props.showNewClientModal && (
					<NewClientModal open onClose={props.onToggleAddClient} />
				)}
				{props.open && !props.showLogoutModal && !props.showNewClientModal && (
					<Modal
						className="onboarding-in-progress-modal"
						open
						noCloseButton
						disableOverlayOnClose
					>
						<h1 className="font-bold text-2xl mb-8">Onboarding in progress</h1>
						<p>
							We are busy processing your registration and a member of our
							onboarding team will reach out to you shortly.
						</p>
						<br />
						<p>
							Once your onboarding has been completed you will be notified via
							email and have full access to this dashboard.
						</p>
						<br />
						<p>If you need any further assistance, please reach out to:</p>
						<a
							href="mailto:onboarding@futureforex.co.za"
							target="_blank"
							rel="noreferrer"
						>
							onboarding@futureforex.co.za
						</a>
						<div className="flex flex-row gap-x-1">
							{
								<Button
									className="mt-9.5 change-client mx-auto"
									type="button"
									theme="primary"
									onClick={() =>
										props.clients.length > 1
											? props.onNavigateClients()
											: props.onToggleAddClient()
									}
								>
									{props.clients.length > 1 ? "BACK" : "ADD ANOTHER CLIENT"}
								</Button>
							}
							<Button
								className="mt-9.5 change-client mx-auto"
								type="button"
								theme="primary"
								onClick={props.onLogout}
							>
								LOGOUT
							</Button>
						</div>
					</Modal>
				)}
			</>
		);
	},
);
