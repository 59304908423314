import React from "react";
import "@ungap/with-resolvers";
import ReactDOM from "react-dom";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/belleza";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://e3e039f375b45a01bef16e1a71039481@o4504921484623872.ingest.us.sentry.io/4507329073446912",
	integrations: [
		Sentry.replayIntegration(),
		Sentry.browserTracingIntegration(),
	],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	tracesSampleRate: 0.3,
	beforeSend(event, hint) {
		if (hint.originalException === "Timeout") return null;
		return event;
	},
	ignoreErrors: [
		"Can't find variable: gmo", // https://issuetracker.google.com/issues/396043331?pli=1
		"ResizeObserver loop limit exceeded", // https://stackoverflow.com/a/50387233
		"Cannot read properties of null (reading 'shadowRoot')", // Scraper error (https://stackoverflow.com/questions/70240354/cannot-read-properties-of-null-reading-shadowroot-java-and-selenium)
		"Object captured as promise rejection with keys: code, message",
		"Object captured as exception with keys: config, data, headers, request, status",
		"Object captured as promise rejection with keys: config, data, headers, request, status",
		"Non-Error exception captured",
		"Non-Error promise rejection captured",
	],
});

if (APP_VERSION) {
	console.log(`Version: ${APP_VERSION}`);
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
);
