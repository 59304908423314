import forexLogo from "@app/assets/images/logo-home.svg";
import { LogoutModal } from "@app/components/modals/logout-modal";
import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import React from "react";
import "./welcome-modal.css";
import { links } from "@app/constants/links";

export const WelcomeModalView = React.memo(
	(props: {
		open: boolean;
		onProceed: () => void;
		showLogoutModal: boolean;
		onCloseLogoutModal: () => void;
		onLogout: () => void;
	}) => {
		return (
			<>
				{props.showLogoutModal && (
					<LogoutModal open onClose={props.onCloseLogoutModal} />
				)}
				<Modal
					disableOverlayOnClose
					className="welcome-modal"
					open={props.open}
					noCloseButton
					overlayColor="light"
				>
					<img src={forexLogo} alt="forex logo" />
					<div
						className="my-12"
						dangerouslySetInnerHTML={{
							__html: `<p>Welcome to Future Forex!</p>
        <p class="my-7">Thank you for registering an account with us.</p>
        <p><b>Before your investment begins, please complete our onboarding process:</b></p>
        <ol>
            <li class="my-3">Add details: <span>As an authorised Financial Services Provider (FSP #51884), we are required to collect some information from you.</span></li>
            <li class="my-3">Capitec Registration: <span>We’ll assist with opening your Capitec Business bank account, a key component of your Crypto Arbitrage investment.</span></li>
            <li class="mt-3">Investment Begins: <span>Once the onboarding process is complete, you will have access to your dashboard and can begin investing!</span></li>
        </ol>
        <p class="mt-7">If you need any help, please <a href="${links.contact}" target="_blank">contact us</a>.</p>
        `,
						}}
					/>
					<div className="flex justify-center mx-auto">
						<Button
							className="w-full font-primary-medium text-lg"
							type="button"
							theme="tertiary"
							onClick={props.onLogout}
						>
							LOGOUT
						</Button>
						<Button
							className="w-full mx-1"
							type="button"
							theme="primary"
							onClick={props.onProceed}
						>
							PROCEED
						</Button>
					</div>
				</Modal>
			</>
		);
	},
);
