import { CustomLoader } from "@app/components/custom-loader";
import { isLoginRedirectToForexEnabled } from "@app/config/feature-flags";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import type { ClientSummariesResponse } from "@app/entities";
import {
	useGetClient,
	useGetClientSummaries,
	useGetProfile,
	useListPeriodSettings,
	useLogout,
	useSetPowerLogin,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import { authorizedApi, setToken } from "@app/services";
import { isArray } from "lodash";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Auth = ({ children }: { children: ReactNode }) => {
	const [getProfile] = useGetProfile();
	const [setPowerLogin] = useSetPowerLogin();
	const [listPeriodSettings] = useListPeriodSettings();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();
	const [logout] = useLogout();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const authToken = searchParams.get("auth-token");
	const loginSource = searchParams.get("login-source");

	const [isPendingSessionCheck, setIsPendingSessionCheck] = useState(true);

	const authState = useSelector((state: RootState) => state.auth) ?? {};
	const { user, userIsAuthenticated } = authState;

	const activeToken = authToken || user?.token;

	const handleLoginRedirect = useCallback(() => {
		const loginSource = window.localStorage.getItem("login-source");
		const isInactive = searchParams.get("inactive") === "true";

		const redirectUrl = `${window.location.pathname}${window.location.search}`;
		window.localStorage.setItem("redirect-url", redirectUrl);

		if (isInactive) {
			navigate(paths.login(isInactive));
			return;
		}

		if (loginSource === "OFP") {
			window.localStorage.removeItem("login-source");
			window.location.href = links.forexLogin();
			return;
		}

		if (isLoginRedirectToForexEnabled) {
			window.location.href = links.forexLogin();
			return;
		}
		navigate(paths.login());
	}, [navigate, searchParams.get]);

	const handleSessionCheck = useCallback(async () => {
		try {
			// Check if the user is still authenticated
			// Similar to verify session check on OFP
			await authorizedApi.get("auth/users/me/");
		} catch (error) {
			logout(() => {
				handleLoginRedirect();
			});
		}
	}, [handleLoginRedirect, logout]);

	useEffect(() => {
		setToken(activeToken || "");
	}, [activeToken]);

	useEffect(() => {
		if (loginSource) {
			window.localStorage.setItem("login-source", loginSource);
		}
	}, [loginSource]);

	useEffect(() => {
		if (authToken) {
			setIsPendingSessionCheck(true);
			setPowerLogin(authToken);

			Promise.allSettled([
				listPeriodSettings(),
				getProfile(),
				getClientSummaries(),
			]).then((responses) => {
				let redirectToClientsScreen = false;

				searchParams.delete("auth-token");

				if ("value" in responses[2] && responses[2].value) {
					if (!isArray(responses[2].value)) {
						const clients = responses[2].value as ClientSummariesResponse;

						if (clients.clients.length > 1) {
							redirectToClientsScreen = true;
						} else if (clients.clients.length === 1) {
							getClient(clients.clients[0].id, () => {
								setIsPendingSessionCheck(false);
							});
						}
					}
				}

				setIsPendingSessionCheck(false);
				if (redirectToClientsScreen) {
					navigate(paths.clients);
				}
			});
		} else if (!userIsAuthenticated) {
			handleLoginRedirect();
		} else {
			//Pre-onboarding flow
			setIsPendingSessionCheck(true);
			handleSessionCheck().then(() => {
				getProfile();
				setIsPendingSessionCheck(false);
			});
		}
		return () => {
			setIsPendingSessionCheck(false);
		};
	}, [userIsAuthenticated]);

	if (user && userIsAuthenticated && !isPendingSessionCheck) return children;
	return <CustomLoader page />;
};
