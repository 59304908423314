import useSWR from "swr";

interface Client {
	id: number;
	first_name: string;
	last_name: string;
	funds_available: number;
	funds_last_updated: string;
	minimum_return: number;
	allowance_available: number;
	total_profit: number;
	trade_status: {
		id: number;
		position: number | null;
		label: string;
		amount_invested: number;
		net_profit: number;
		statement: boolean;
	};
	trade_status_v2: {
		status_slug: string;
		status_secondary_text: string;
		status_description: string;
		status_description_icon: string;
		amount_invested: number;
		net_profit: number;
		statement: boolean;
	};
	rm: {
		first_name: string;
		last_name: string;
		contact_number: string;
		email: string;
		picture: string;
	};
	default_stat: {
		title: string;
		slug: string;
		chart_type: string;
		data: null[];
	};
	deposit_bank: {
		bank: string;
		deposit_account_number: string;
		branch_code: string;
		account_type: string;
	};
	onboarding_complete: boolean;
	spouse: {
		id: number;
		first_name: string;
		last_name: string;
	};
	kraken_api_test_success: boolean;
}

export const useClient = (clientId?: number) => {
	return useSWR<Client>(clientId ? `/get_client/${clientId}` : null);
};
