import { apiErrors } from "@app/constants/api-errors";
import { paths } from "@app/constants/paths";
import { useLogout } from "@app/helpers";
import type { RootState } from "@app/redux";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OnboardingInProgressModalView } from "./onboarding-in-progress-modal-view";
import type { Properties } from "./properties";

interface OnboardingModalState {
	showLogoutModal: boolean;
	showNewClientModal: boolean;
}

export const OnboardingInProgressModal = (props: Properties) => {
	const [logout] = useLogout();

	const navigate = useNavigate();

	const { clients } = useSelector((rootState: RootState) => rootState.clients);

	const defaultState: OnboardingModalState = {
		showLogoutModal: false,
		showNewClientModal: false,
	};

	const [state, setState] = useState<OnboardingModalState>(defaultState);

	const onNavigateClients = () => {
		navigate(paths.clients);
	};

	const onCloseLogoutModal = () => {
		setState({ ...state, showLogoutModal: false });
	};

	const onLogout = () => {
		logout((errors?: string[]) => {
			const showLogout = !(
				errors &&
				(errors.includes(apiErrors.tokenExpired) ||
					errors.includes(apiErrors.invalidToken))
			);
			setState({ ...state, showLogoutModal: showLogout });
		});
	};

	const onToggleAddClient = () => {
		setState({ ...state, showNewClientModal: !state.showNewClientModal });
	};

	return (
		<OnboardingInProgressModalView
			{...props}
			clients={clients}
			showLogoutModal={state.showLogoutModal}
			showNewClientModal={state.showNewClientModal}
			onCloseLogoutModal={onCloseLogoutModal}
			onLogout={onLogout}
			onNavigateClients={onNavigateClients}
			onToggleAddClient={onToggleAddClient}
		/>
	);
};
