import styles from "./index.module.css";
import { useClient } from "./use-client";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { formatNumber } from "@app/utils/format-number";
import { Fragment, useMemo } from "react";
import activeSrc from "./active.svg";
import dangerSrc from "./danger.svg";
import inactiveSrc from "./inactive.svg";
import infoSrc from "./info.svg";
import successSrc from "./success.svg";
import warningSrc from "./warning.svg";

const getStatusIcon = (status?: string) => {
	if (!status) return null;
	if (status === "info") return infoSrc;
	if (status === "warning") return warningSrc;
	if (status === "success") return successSrc;
	if (status === "danger") return dangerSrc;
	return null;
};

const getFormattedAmount = (value: number | string, isMobile: boolean) => {
	if (!value) return "-";
	if (typeof value === "string") return value;
	return formatNumber(value, {
		currencyCode: "R ",
		decimalPlaces: isMobile ? 0 : 2,
		groupSeparator: ",",
	});
};

const getNetProfitDisplay = (
	investedAmount: number | string,
	netProfit: number | string,
	isMobile: boolean,
) => {
	const isNoNetProfitSet = !netProfit || netProfit === "TBC";
	if (investedAmount && !isNoNetProfitSet) return "TBC";
	return getFormattedAmount(netProfit, isMobile);
};

export const TradeStatusV2 = ({ clientId }: { clientId?: number }) => {
	const isMobile = useMediaQuery();
	const { data, isLoading, error } = useClient(clientId);

	const statusIconSrc = getStatusIcon(
		data?.trade_status_v2.status_description_icon,
	);

	const stages = useMemo(() => {
		if (!data) return [];
		const hideDescription =
			data.trade_status_v2.status_secondary_text ===
			"Contact Relationship Manager";

		const isNotTrading = data.trade_status_v2.status_slug === "not_trading";
		const isTradeLoaded = data.trade_status_v2.status_slug === "trade_loaded";
		const isTradeProcessing =
			data.trade_status_v2.status_slug === "trade_processing";

		return [
			{
				isActive: isNotTrading,
				label: "Not Trading",
				description:
					!hideDescription && isNotTrading
						? data.trade_status_v2.status_secondary_text
						: null,
			},
			{
				isActive: isTradeLoaded,
				label: "Trade Loaded",
				description:
					!hideDescription && isTradeLoaded
						? data.trade_status_v2.status_secondary_text
						: null,
			},
			{
				isActive: data.trade_status_v2.status_slug === "trade_processing",
				label: "Trade Processing",
				description:
					!hideDescription && isTradeProcessing
						? data.trade_status_v2.status_secondary_text
						: null,
			},
		];
	}, [data]);

	if (!data || isLoading || error) {
		return (
			<div className={styles.container}>
				<h2 className={styles.title}>Trade Status</h2>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<h2 className={styles.title}>Trade Status</h2>

			<div className={styles.progressTrack}>
				{stages.map((current, index) => (
					<Fragment key={current.label}>
						{index !== 0 && <div className={styles.divider} />}
						<img
							key={current.label}
							src={current.isActive ? activeSrc : inactiveSrc}
							alt=""
							width={20}
							height={20}
						/>
					</Fragment>
				))}
			</div>
			<ul className={styles.stages}>
				{stages.map((current) => (
					<li key={current.label} className={styles.stage}>
						<p data-active={current.isActive} className={styles.stageTitle}>
							{current.label}
						</p>
						{current.description && (
							<p
								dangerouslySetInnerHTML={{
									__html: current.description,
								}}
							/>
						)}
					</li>
				))}
			</ul>

			<div
				className={styles.status}
				data-variant={data.trade_status_v2.status_description_icon}
			>
				{statusIconSrc && (
					<img
						src={statusIconSrc}
						alt=""
						width={20}
						height={20}
						className={styles.statusIcon}
					/>
				)}
				<p
					dangerouslySetInnerHTML={{
						__html: data.trade_status_v2.status_description,
					}}
				/>
			</div>

			<ul className={styles.list}>
				<li className={styles.listItem}>
					<p>Invested Amount:</p>
					<p>
						{getFormattedAmount(data.trade_status_v2.amount_invested, isMobile)}
					</p>
				</li>
				<li className={styles.listItem}>
					<p>Net Profit:</p>
					<p>
						{getNetProfitDisplay(
							data.trade_status_v2.amount_invested,
							data.trade_status_v2.net_profit,
							isMobile,
						)}
					</p>
				</li>
			</ul>
		</div>
	);
};
