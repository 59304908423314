import React, { useEffect, useState } from "react";
import { ClientAccordionView } from "./client-accordion-view";
import type { Properties } from "./properties";

interface ClientAccordionState {
	open: boolean;
}

export const ClientAccordion = (props: Properties) => {
	const defaultState: ClientAccordionState = {
		open: props.open || false,
	};

	const [state, setState] = useState<ClientAccordionState>(defaultState);

	const onViewClient = (identifier: number) => {
		props.onViewClient(identifier);
	};

	const onToggleOpen = (open?: boolean) => {
		setState({ ...state, open: open ?? !state.open });
	};

	useEffect(() => {
		if (props.open !== undefined && props.open !== state.open) {
			setState({ ...state, open: props.open });
		}
	}, [props.open]);

	return (
		<ClientAccordionView
			{...props}
			open={state.open}
			onViewClient={onViewClient}
			onToggleOpen={onToggleOpen}
		/>
	);
};
