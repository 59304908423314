import type { TradeStatusV2 } from "@app/hooks/use-client-summaries";

import dangerSrc from "./danger.svg";
import stage1Src from "./stage-1.svg";
import stage2Src from "./stage-2.svg";
import stage3Src from "./stage-3.svg";
import successSrc from "./success.svg";
import warningSrc from "./warning.svg";

import styles from "./index.module.css";

const getStatusFromSlug = (slug: TradeStatusV2["status_slug"]) => {
	if (slug === "not_trading") return "Not Trading";
	if (slug === "trade_loaded") return "Trade Loaded";
	if (slug === "trade_processing") return "Trade Processing";
};

const getStageFromDescription = (description: string) => {
	if (description.includes("Awaiting AIT approval")) return stage1Src;
	if (description.includes("Awaiting market conditions")) return stage2Src;
	if (description.includes("Settling funds")) return stage3Src;
};

const ICON_SIZE = 24;

export const TradeStatusCell = ({
	tradeStatus,
}: {
	tradeStatus: TradeStatusV2;
}) => {
	return (
		<div className={styles.container}>
			{tradeStatus.status_description_icon === "warning" && (
				<img src={warningSrc} alt="" width={ICON_SIZE} height={ICON_SIZE} />
			)}
			{tradeStatus.status_description_icon === "danger" && (
				<img src={dangerSrc} alt="" width={ICON_SIZE} height={ICON_SIZE} />
			)}
			{tradeStatus.status_description_icon === "info" && (
				<img
					src={getStageFromDescription(tradeStatus.status_secondary_text)}
					alt=""
					width={ICON_SIZE}
					height={ICON_SIZE}
				/>
			)}
			{tradeStatus.status_description_icon === "success" && (
				<img src={successSrc} alt="" width={ICON_SIZE} height={ICON_SIZE} />
			)}
			<div>
				<p className={styles.title}>
					{getStatusFromSlug(tradeStatus.status_slug)}
				</p>
				<p className={styles.description}>
					{tradeStatus.status_secondary_text}
				</p>
			</div>
		</div>
	);
};
