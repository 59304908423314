import type { Language } from "src/types";

export const pathParams = {
	token: "paramToken",
	user: "paramUser",
};

export const paths = {
	account: "/account",
	activation: `/activate/:${pathParams.user}/:${pathParams.token}`,
	activationBase: "/activate",
	clients: "/clients",
	dashboard: "/",
	forgotPassword: "/reset-password",
	login: (isInactive = false) => `/login${isInactive ? "?inactive=true" : ""}`,
	logout: "/logout",
	notFound: "/404",
	register: "/register",
	reset: `/password-reset/:${pathParams.user}/:${pathParams.token}`,
	resetBase: "/password-reset",
	trades: "/trades",
};

export const pathNames: {
	[key in Language]: {
		[key in keyof typeof paths]: string;
	};
} = {
	en: {
		account: "Account Settings",
		activation: "Activation",
		activationBase: "Activation",
		clients: "View Clients",
		dashboard: "Dashboard",
		forgotPassword: "Forgot Password",
		login: "Login",
		logout: "Logout",
		notFound: "Not Found",
		register: "Register",
		reset: "Reset Password",
		resetBase: "Reset Password",
		trades: "Trades",
	},
};
