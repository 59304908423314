import { Modal } from "@app/components/modals/modal";
import successTick from "../../../assets/images/wizard-last-step.svg";
import "./withdraw-success-modal.css";

export const WithdrawSuccessModal = ({
	open,
	straightThroughProcess,
	onClose,
}: {
	open: boolean;
	straightThroughProcess: boolean;
	onClose: () => void;
}) => {
	const processed = straightThroughProcess
		? "This will be processed in the next 1-2 business days"
		: "This will be processed in the next 3-5 business days";
	return (
		<Modal className="withdraw-success-modal" open={open} onClose={onClose}>
			<img className="mb-8 mx-auto" src={successTick} alt="" />
			<h1 className="font-primary-bold mb-2.5 text-center">
				Withdrawal request received
			</h1>
			<h5 className="text-center">{processed}</h5>
		</Modal>
	);
};
