import desktopGraphicSrc from "./graphic-desktop.jpg";
import mobileGraphicSrc from "./graphic-mobile.jpg";

import styles from "./index.module.css";

export const ForexBanner = () => {
	return (
		<div className={styles.container}>
			<img
				className={styles.desktopGraphic}
				src={desktopGraphicSrc}
				alt=""
				width={236}
				height={234}
			/>
			<img
				className={styles.mobileGraphic}
				src={mobileGraphicSrc}
				alt=""
				width={336}
				height={112}
			/>
			<div className={styles.content}>
				<h3 className={styles.title}>
					International money transfers, made simple
				</h3>
				<p className={styles.description}>
					Moving funds for offshore investments, emigration, imports/exports,
					etc has never been easier.
				</p>
				<a
					className={styles.link}
					href="https://futureforex.co.za/contact/"
					target="_blank"
					rel="noreferrer noopener"
				>
					Get in touch
				</a>
			</div>
		</div>
	);
};
