import { isLocal } from "@app/config/env";
import { type Action, configureStore } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	type PersistConfig,
	REGISTER,
	REHYDRATE,
	createTransform,
	persistReducer,
	persistStore,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import type { TransformConfig } from "redux-persist/lib/createTransform";
import storage from "redux-persist/lib/storage";
import type { ThunkAction } from "redux-thunk";
import rootReducer, { type RootState } from "./reducer";

const storeName = isLocal ? "persist-state" : "encrypted-state";
const decryptionFailed = "Could not decrypt state";

let reloadWindow = false;

const getRegexTransformer = (
	blacklist?: RegExp[],
	whitelist?: RegExp[],
	config?: TransformConfig,
) => {
	const filter = (state: any, blacklist?: RegExp[], whitelist?: RegExp[]) => {
		if (typeof state !== "object") return state;

		let keys = Object.keys(state);

		if (whitelist) keys = keys.filter((x) => whitelist.some((y) => y.test(x)));
		if (blacklist) keys = keys.filter((x) => !blacklist.some((y) => y.test(x)));

		return keys.reduce(
			(prev, current) => ({ ...prev, [current]: state[current] }),
			{},
		);
	};

	return createTransform(
		(inboundState) => filter(inboundState, blacklist, whitelist),
		(outboundState) => filter(outboundState, blacklist, whitelist),
		config,
	);
};

const regexTransformer = getRegexTransformer([
	/loading/i,
	/error/i,
	/newPageLoad/i,
]);

const encryptTransformer = encryptTransform({
	secretKey: CryptoJS.SHA256("E21A137453F175E3931315A8B12D1").toString(),
	onError: (error) => {
		if (error.message.indexOf(decryptionFailed) > -1) {
			const navigationList = window.performance.getEntriesByType("navigation");
			let wasReloaded = false;

			if (navigationList.length > 0) {
				const firstItemInNavigationList = navigationList[0];
				if ("type" in firstItemInNavigationList)
					wasReloaded = firstItemInNavigationList.type === "reload";
			}

			if (!wasReloaded) reloadWindow = true;
		}
	},
});

const persistConfig: PersistConfig<any> = {
	key: storeName,
	storage: storage,
	transforms: isLocal
		? [regexTransformer]
		: [regexTransformer, encryptTransformer],
};

export const store = configureStore({
	reducer: persistReducer(persistConfig, rootReducer),
	devTools: isLocal,
	enhancers: [],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const storePersist = persistStore(store, undefined, () => {
	if (reloadWindow) location.reload();
});
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
