const NotFound = () => {
	return (
		<div className="py-5">
			<h1>Not Found</h1>
			<p>The page you are looking for could not be found</p>
		</div>
	);
};

export default NotFound;
